<template>
	<section class="dashboard dashboard--startups">
		<header class="dashboard__header">
			<h3 class="heading3">Startups</h3>
			<a v-if="false" href="#" class="dashboard__actions"></a>
            
            <div v-if="false" class="sort-section">
                <a class="sort-section__up" href="#"></a>
                <a class="sort-section__down" href="#"></a>
            </div>
		</header>

		<div class="dashboard__content">
			<div v-if="loading">Loading...</div>
			<div class="text-center" style="width: 100%" v-if="!loading && isEmpty">
				<h4 class="heading4 mb-4">Keep Tabs On Your Startups</h4>
				<p>Add Startups to perform diligence and add them to your trackers</p>
				<b-button class="btn-primary btn-lg" @click="$refs.addStartupModal.open()">Add A Startup</b-button>
			</div>
			<ul class="dashboard__stats" v-if="!loading && !isEmpty">
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="addedStartupsCount"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>Startups added by {{ $store.state.user.company_name }} users</p>
				</li>
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="startupsWithActivityCount"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>Startups with activity</p>
				</li>
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="startupsAssociatedWithTrackersCount"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>On trackers</p>
				</li>
			</ul>

			<div class="dashboard__content__inner" v-if="!loading && !isEmpty">
				<b-row>
					<b-col sm="12">
						<h4 class="heading4">Most active startups</h4>
						<ul class="rows-list rows-list--secondary rows-list--cards">
							<li v-for="startup in mostActiveStartups" :key="startup.id">
                                <div class="rows-list--cards__content">
                                    <figure class="image-block image-block--xs">
                                        <img :src="LogoHelper.getLogo(startup.logo_url)" alt=""/>
                                    </figure>
                                    <div>
                                        <h5 class="heading5"><a :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a></h5>
                                        <p>{{ startup.cnt.toLocaleString() }} {{stringHelper.getNoun('interaction', startup.cnt)}}</p>
                                    </div>
                                </div>
                                <div class="rows-list--cards__footer rows-list--cards__footer--startups">
                                    <div class="rows-list--cards__footer__inner">
                                        <template v-if="startup.trackers.length === 0">
                                            <span>Not in any tracker</span>
                                        </template>
                                        <template v-else-if="startup.trackers.length === 1">
                                            <a :href="'/trackers/' + startup.trackers[0].id" target="_blank">{{startup.trackers[0].name}}</a>
                                        </template>
                                        <template v-else>
                                            <a @click.prevent="$refs.startupTrackersListModal.open(startup)" target="_blank">In {{startup.trackers.length}} Trackers</a>
                                        </template>
                                    </div>
                                </div>
							</li>
						</ul>
                        <StartupTrackersListModal ref="startupTrackersListModal"/>
					</b-col>
				</b-row>
			</div>
		</div>
		<add-startup ref="addStartupModal" prefix="home-startups" v-on:startupsAdded="void updateAll()"></add-startup>
	</section>
</template>
<script>
import dashboard from '@/services/dashboard.service';
import AnimatedNumber from 'animated-number-vue';

export default {
	data() {
		return {
			loading: false,
            addedStartupsCount: 0,
            mostActiveStartups: [],
            startupsWithActivityCount: 0,
            startupsAssociatedWithTrackersCount: 0
		}
	},
	name: 'Startups',
	props: {
        filters: Object,
        selectedPeriod: String,
		userType: String
	},
    watch: {
        filters() {
            this.updateAll();
        },
    },
	methods: {
		async updateAll() {
			try {
				this.loading = true;
				await Promise.all([
					this.fetchAddedStartupsCount(),
					this.fetchStartupsWithActivityCount(),
                    this.fetchAssociatedWithTrackerCount(),
                    this.fetchMostActiveStartups(),
                ]);
				this.loading = false;
			} catch (e) {
				console.error(e);
				this.loading = false;
			}
		},
		async fetchAddedStartupsCount() {
            const response = await dashboard.getWidgetData('AddedStartupsCount', this.filters);
			this.addedStartupsCount = response.data;
		},
        async fetchStartupsWithActivityCount() {
            const response = await dashboard.getWidgetData('StartupsWithActivityCount', this.filters);
            this.startupsWithActivityCount = response.data;
        },
        async fetchAssociatedWithTrackerCount() {
            const response = await dashboard.getWidgetData('StartupsAssociatedWithTrackersCount', this.filters);
            this.startupsAssociatedWithTrackersCount = response.data;
        },
        async fetchMostActiveStartups() {
            const response = await dashboard.getWidgetData('MostActiveStartups', this.filters);
			this.mostActiveStartups = response.data;
		},
	},
	mounted() {
		this.$store.watch(state => {
            if (state.needUpdateStartups) {
                void Promise.all([
					this.fetchMostActiveStartups()
				])
            }

            if (state.accountType.toString() !== 'premium') {
                void this.updateAll();
            }
		});
		void this.updateAll();
	},
	computed: {
		isEmpty() {
			return (
				+this.addedStartupsCount === 0 &&
				this.mostActiveStartups.length === 0 &&
				+this.startupsWithActivityCount === 0 &&
				+this.startupsAssociatedWithTrackersCount === 0
			);
		}
	},
	components: {
        addStartup: () => import("@/components/startups/AddStartup.vue"),
		AnimatedNumber,
        StartupTrackersListModal: () => import('@/views/enterprise/old/components/StartupTrackersListModal.vue'),
	}
}
</script>
